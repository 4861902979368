export default {
  TOKEN: 'whajjOOks%%--s',
  STORAGE_TOKEN: 'kay&sqq_storage',

  LOADING: "GET_DATA_LOADING",

  REHYDRATE_VAULT: "REHYDRATE_VAULT",

  NETWORK_CHAINID: '0x38',
  ACCOUNT_BALANCE: 'ACCOUNT_BALANCE',

  ADDRESS: 'ADDRESS',
  WALLETINUSE: 'WALLETINUSE',
  CONNDETAILS: 'CONN_DETAILS',
  APYS: "ALL_APYS",
  NATIVE_BALANCE: 'NATIVE_BALANCE',

  PRISTINE: 'ALL_PRISTINE',
  HIGHESTAPYXVAULT: 'HIGHESTAPYXVAULT',
  HIGHESTAPYXAUTO: 'HIGHESTAPYXAUTO',
  HIGHESTAPYXAUTOMATIC: 'HIGHESTAPYXAUTOMATIC',

  PROTOCOLS: 'ALL_STABLECOIN_OFFERINGS',
  LENDER: 'LENDER_PROTOCOLS',
  //providerSet
  WEB3SETPROVIDER: 'WEB3_SET_PROVIDER',

  NETWORK_CONNECT: 'NETWORK_CONNECT',
  CONWALLETADD: 'WALLETCONN',

  //BSC
  USDTBALANCE: 'USDT BALANCE',
  BUSDBALANCE: 'BUSD BALANCE',
  USDCBALANCE: 'USDC BALANCE',
  BNBBALANCE: 'BNB BALANCE WALLET BALANCE',

  //MATIC
  USDTBALANCEMatic: 'USDT BALANCE MATIC',
  USDCBALANCEMatic: 'USDC BALANCE MATIC',
  AAVEBALANCEMatic: 'AAVE BALANCE MATIC',
  WBTCBALANCEMatic: 'WBTC BALANCE MATIC',

  BUSDDEPOSITBALANCE: 'BUSD USER DEPOSIT BALANCE',
  USDTDEPOSITBALANCE: 'USDT USER DEPOSIT BALANCE',
  USDCDEPOSITBALANCE: 'USDC USER DEPOSIT BALANCE',

  userBUSDDEPOSITBALANCEXAuto: 'BUSD USER DEPOSIT BALANCE X Auto',
  userUSDTDEPOSITBALANCEXAuto: 'USDT USER DEPOSIT BALANCE X Auto',
  userUSDCDEPOSITBALANCEXAuto: 'USDC USER DEPOSIT BALANCE X Auto',
  userBNBDEPOSITBALANCEXAuto: 'BNB USER DEPOSIT BALANCE X Auto',

  AAVEDEPOSITBALANCEMatic: 'AAVE USER DEPOSIT BALANCE MATIC',
  USDTDEPOSITBALANCEMatic: 'USDT USER DEPOSIT BALANCE MATIC',
  USDCDEPOSITBALANCEMatic: 'USDC USER DEPOSIT BALANCE MATIC',
  WBTCDEPOSITBALANCEMatic: 'WBTC USER DEPOSIT BALANCE MATIC',


  DashboardGrid: 'DashboardGrid',
  DashboardGridMatic: 'DashboardGridMatic',

  //providerSet
  WCP: 'WCProvider',

  //web3HasDisconnected
  WEB3_WALLETCONNECT_HAS_DISCONNECTED: 'WEB3_WALLETCONNECT_DISCONNECTED',

  //NewNetworkChange
  NETWORK_PROVIDER_HAS_CHANGED: 'SELECTED_NETWORK_PROVIDER',

  STORED_APYS: "__stored_apy__",
  APY_NOTICE: "APY_NOTICE",
};
